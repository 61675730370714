<template>
  <section id="privacy" class="privacy-policy">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-10 mx-auto">
          <h2 class="sec-title mb-4">Kebijakan Privasi</h2>
          <small class="text-muted">Terakhir diperbarui: Maret 03, 2025</small>
          <p class="description mt-2">Selamat datang di SIATAB (Sistem Informasi Air Tanah dan Air Baku), yang dikelola
            oleh Direktorat
            Jenderal Sumber Daya Air, Kementerian Pekerjaan Umum Republik Indonesia. Kami berkomitmen untuk
            melindungi privasi dan keamanan informasi pengguna saat mengakses dan menggunakan situs ini
          </p>
          <div class="card shadow-sm">
            <div class="card-body">
              <section class="mb-4">
                <h2 class="h3 mb-3">1. Informasi yang Kami Kumpulkan</h2>
                <p class="description">Saat Anda mengakses SIATAB, kami dapat mengumpulkan informasi berikut: </p>
                <ul class="list-group list-group-flush list-group-numbered">
                  <li class="list-group-item">Informasi Identitas: Seperti nama, alamat email, dan informasi lain yang
                    Anda berikan secara
                    sukarela melalui
                    formulir atau registrasi.
                  </li>
                  <li class="list-group-item">Informasi Teknis: Seperti alamat IP, jenis perangkat, jenis browser,
                    halaman yang dikunjungi,
                    serta
                    waktu dan durasi akses.</li>
                  <li class="list-group-item">Cookies dan Teknologi Pelacakan: Kami dapat menggunakan cookie untuk
                    meningkatkan pengalaman
                    pengguna dan menganalisis penggunaan situs web.</li>
                </ul>
              </section>
              <section class="mb-4">
                <h2 class="h3 mb-3">2. Penggunaan Informasi</h2>
                <p class="description">Informasi yang kami kumpulkan dapat digunakan untuk:</p>
                <ul class="list-group list-group-flush list-group-numbered">
                  <li class="list-group-item">Memproses dan memberikan layanan yang tersedia di SIATAB</li>
                  <li class="list-group-item">Meningkatkan kinerja dan pengalaman pengguna dalam menggunakan layanan
                    kami.</li>
                  <li class="list-group-item">Mengelola komunikasi, seperti tanggapan terhadap pertanyaan atau
                    permintaan dukungan.</li>
                  <li class="list-group-item">Mematuhi ketentuan hukum dan peraturan yang berlaku</li>
                </ul>
              </section>
              <section class="mb-4">
                <h2 class="h3 mb-3">3. Perlindungan dan Keamanan Data</h2>
                <p class="description">Kami menerapkan langkah-langkah keamanan yang wajar untuk melindungi data
                  pengguna dari akses yang
                  tidak sah, penggunaan yang tidak sah,
                  atau pengungkapan yang tidak sah. Namun, perlu diketahui bahwa tidak ada metode transmisi data melalui
                  internet yang sepenuhnya aman.
                </p>
              </section>
              <section class="mb-4">
                <h2 class="h3 mb-3">4. Berbagi Informasi dengan Pihak Ketiga</h2>
                <p class="description">Kami tidak menjual, menyewakan, atau menukar informasi pribadi Anda dengan pihak
                  ketiga tanpa
                  persetujuan Anda, kecuali dalam keadaan berikut:</p>
                <ul class="list-group list-group-flush list-group-numbered">
                  <li class="list-group-item">Jika diwajibkan oleh hukum atau peraturan yang berlaku.</li>
                  <li class="list-group-item">Jika diperlukan untuk keperluan penyelidikan, pencegahan, atau tindakan
                    terhadap aktivitas ilegal
                  </li>
                </ul>
              </section>
              <section class="mb-4">
                <h2 class="h3 mb-3">5. Hak dan Pilihan Pengguna</h2>
                <p class="description">Pengguna memiliki hak untuk:</p>
                <ul class="list-group list-group-flush list-group-numbered">
                  <li class="list-group-item">Mengakses, memperbarui, atau menghapus informasi pribadi mereka yang telah
                    disediakan.</li>
                  <li class="list-group-item">Menolak penggunaan cookie melalui pengaturan browser mereka.</li>
                  <li class="list-group-item">Menghubungi kami untuk pertanyaan lebih lanjut tentang kebijakan privasi
                    ini</li>
                </ul>
              </section>
              <section class="mb-4">
                <h2 class="h3 mb-3">6. Perubahan Kebijakan Privasi</h2>
                <p class="description">Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Setiap
                  perubahan akan diberitahukan
                  melalui situs ini dan berlaku setelah diperbarui.</p>
              </section>
              <section class="mb-4">
                <h2 class="h3 mb-3">7. Kontak Kami</h2>
                <p class="description">Jika Anda memiliki pertanyaan atau permintaan terkait kebijakan privasi ini, Anda
                  dapat menghubungi
                  kami melalui:</p>
                <div>
                  <p class="description mb-1 mt-2">Direktorat Jenderal Sumber Daya Air</p>
                  <p class="description mb-1">Kementerian Pekerjaan Umum Republik Indonesia</p>
                  <p class="description mb-1">
                    Email: balaiairtanah@gmail.com
                  </p>
                  <p class="description mb-1">
                    Alamat: Jl. Ir. H. Juanda No.193, Dago, Kec. Coblong, Kota Bandung, Jawa Barat 40135
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axiosIns from "@/libs/axios";

export default {
  name: "Content",
  data() {
    return {

    };
  },
  created() {
  },
  methods: {

  },
};
</script>

<style>
.privacy-policy {
  padding-top: 140px;
}

/* . {
  background-color: transparent;
  border-color: #e9ecef;
} */

.description {
  text-indent: 30px;
  text-align: justify;
  margin-bottom: 8px;
}

.policys {}

.policy {}

.policyItem {}

.list-group list-group-flush ul {
  list-style-type: disc !important;
  padding-left: 1em !important;
  margin-left: 1em;
  /* list-style-position: outside; */
  /* list-style-image: none; */
} 

.list-group list-group-flush ul li {
  /* background-color: transparent; */
  /* border-color: #e9ecef; */

}

@media (min-width: 576px) {
  .privacy-policy {
    /* padding-top: 200px; */
  }
}
</style>
