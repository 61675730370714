<template>
  <div>
    <Header />
    <div class="" id="smooth-wrapper">
      <div class="" id="smooth-content">
        <main>
          <div class="home-wrapper-4">
              <Content />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  </div>
</template>


<script>

import "@/assets/landing/assets/css/bootstrap.min.css";
import "@/assets/landing/assets/css/all.min.css";
import "@/assets/landing/assets/css/custom.css";
import "@/assets/landing/assets/css/master.css";

import Header from "@/views/components/landing/Header.vue";
import Footer from "@/views/components/landing/Footer.vue";
import Content from "@/views/components/privacyPolicy/Content.vue";

export default {
  name: 'privacy-policy',
  metaInfo: {
    title: 'Privacy Policy',
    meta: [
      { name: 'description', content: 'Privacy Policy for siatab.sda.pu.go.id' }
    ]
  },
  components: {
    Header,
    Footer,
    Content,
  },
  data() {
    return {
    };
  },
  methods: {

  },

}
</script>

<style scoped>
.header__area-3 {
  background: #f8f8f8;
}
</style>